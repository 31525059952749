import React from "react";
import settings from "settings";
import { Translation, Trans } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import LocationFilter from "./locationFilter";
import LanguageFilter from "./languageFilter";
import BrandsFilter from "./brandsFilter";
import LookalikesFilter from "./lookalikesFilter";
import PartnershipFilter from "./partnershipFilter";
import InterestsFilter from "./interestsFilter";
import GenderFilter from "./genderFilter";
import GrowthFilter from "./growthFilter";
import EthnicityFilter from "./ethnicityFilter";
import AgeFilter from "./ageFilter";
import FollowersFilter from "./followersFilter";
import ViewsFilter from "./viewsFilter";
import ReelsFilter from "./reelsFilter";
import EngagementFilter from "./engagementFilter";
import OtherFilters from "./otherFilters";
import CommonSelectedFilters from "./commonSelectedFilters";
import RecordsList from "./recordsList";
import SearchBoxComponent from "./searchBoxComponent";
import UniversityFilter from "./universityFilter";

import IntegrationSelection from "./integrationSelection";

import { Formik, Form } from "formik";
// import Bitmap2 from "../../img/Bitmap2.png";
// import Bitmap from "../../img/Bitmap.png";
// import { getUserBalanceData } from "../header/actions";\
import Loader from "../../loader";
import otherFiltersOptions from "../../helpers/otherFiltersOptions";
import {
  getInfluencerRecords,
  getLocationsBasedOnFilter,
  showLoadingAction,
  clearTagsErrorMSG,
  clearExportsErrorMSGAction,
  checkExportsRemaining,
  postForExportCSV,
  getUniversitiesBasedOnFilter,
  getFilteredBrands,
  getFilteredLookalikes,
  getFilteredInterests,
  unlockMoreResultsAction,
  getCloudTags,
  getTopicTags,
  getAuthIntegrations,
  getAvailableAccounts,
} from "./actions/index";
import {
  checkSubscription,
  createCheckoutSession,
} from "../login/actions/index";
import { connect } from "react-redux";
import tableColumns from "../../helpers/tableColumns";
import {
  formatNumbersToCommaSeperated,
  showErrorMessage,
  showSuccessMessage,
} from "../../actions";
import BioFilter from "./biofilter";
import ContactsFilter from "./contactsFilter";
import { signOut, clearPreviouslyExportedIds } from "../login/actions/index";
import {
  getInfluencerProfileDataBySearch,
  clearReportsErrorMsg,
  toggleViewModal,
} from "../reports/actions/index";
import {
  getCreatorLists,
  addCreatorList,
  updateCreatorList,
} from "../creatorList/actions/index";
import UnlockAccountsModal from "./unlockAccountsModal";
import AccountTypeFilter from "./accountTypeFilters";
import AudienceTypeFilter from "./audienceTypeFilter";
import LastPostFilter from "./lastPostFilter";
import accountTypeFilterOptions from "../../helpers/accountTypeFilterOptions";
import {
  clearSavedListFilters,
  saveListSearchFilters,
} from "../userProfile/actions/index";
import calculateInitialStateFromSavedFiltersList from "./calculateInitialStateFromSavedFiltersList";
import calculateSearchObj from "./calculateSearchObjForInfluencerRecords";
import calculateDataForFilters from "./calculateDataForFilters";
import ViewReportModal from "./viewReportModal";
import CreatorReportModal from "modules/reports/modals/creatorReportModal";

import { clearContinueWithTrialAccessSuccessMsgAction } from "../paymentPortal/actions/index";
import Paginator from "../../components/pagination/pagination";
import { CHECKOUT_APP_EXTERNAL_URL } from "../../actions";
import { store } from "../../store";
import { debounce } from "lodash";
import networkImage from "helpers/networkImage";

class IdentificationComponent extends React.Component {
  constructor(props) {
    super(props);
    let defaultColumns = [];
    let accountTypeFiltersOptions = [...accountTypeFilterOptions];
    tableColumns.map((option) => defaultColumns.push(option));

    if (settings.pages.creator_lists.enabled) {
      defaultColumns.shift();
    }

    let otherFilters = {};
    (otherFiltersOptions || []).map(
      (filter) => (otherFilters[filter.value] = false)
    );
    this.state = {
      audienceLanguage: null,
      bio: "",
      keywords: "",
      showRelevance: false,
      relevance: [],
      username: "",
      audienceLocationValues: [],
      universityValue: null,
      influencerLocationValues: [],
      contactsValues: [],
      influencerLanguage: null,
      audienceBrandsValues: [],
      influencerBrandsValues: [],
      audienceLookalikeValue: "",
      influencerLookalikeValue: "",
      influencerPartnershipValues: [],
      audienceInterestsValues: [],
      influencerInterestsValues: [],
      languageWeight: { label: "> 10%", value: 0.1 },
      genderWeight: { label: "> 10%", value: 0.1 },
      followersGrowth: null,
      likesGrowth: null,
      viewsGrowth: null,
      followersGrowthWeight: { label: "> 150%", value: 1.5 },
      likesGrowthWeight: { label: "> 150%", value: 1.5 },
      viewsGrowthWeight: { label: "> 150%", value: 1.5 },
      ethnicityWeight: { label: "> 10%", value: 0.1 },
      audiencesAgeLeft: null,
      audiencesAgeRight: null,
      audiencesAgeWeight: { label: "> 10%", value: 0.1 },
      influencersAgeLeft: null,
      influencersAgeRight: null,
      followersFrom: null,
      followersTo: null,
      viewsFrom: null,
      viewsTo: null,
      reelsFrom: null,
      reelsTo: null,
      engagementsInfluencersFrom: null,
      engagementsInfluencersTo: null,
      engagementRate: null,
      engagementRateOperator: { label: "Greater than or equal", value: "gte" },
      genderAudience: null,
      genderInfluencer: null,
      ethnicityAudience: null,
      audienceType: null,
      lastPost: false,
      editColumnsMenuOpen: false,
      sortMenuOpen: false,
      windowWidth: window.innerWidth,
      currentPage: 1,
      pageSize: { label: "10", value: 10 },
      filters: {
        bio: false,
        contact: false,
        location: false,
        language: false,
        brands: false,
        lookalikes: false,
        interest: false,
        gender: false,
        growth: false,
        ethnicity: false,
        age: false,
        followers: false,
        engagements: false,
        settings: false,
        accountTypeFilters: false,
        audienceType: false,
        university: false,
        lastPost: false,
        partnership: false,
        views: false,
        reels: false,
      },
      otherFilters: otherFilters,
      accountTypeFiltersOptions: accountTypeFiltersOptions,
      accountTypeFilters: { Regular: false, Business: false, Creator: false },
      selectedThreeTableColumns: [],
      defaultColumns: defaultColumns,
      allSelectedFilters: [],
      sortColumn: "followers",
      sortDesc: true,
      noOfInfluencersNeeded: "",
      noOfInfluencersNeededInDropdown: null,
      influencerNeededType: "dropdown",
      openViewModal: false,
      includeContact: true,
      removeDuplicates: false,
      exportTotal: 0,
      cursorX: 0,
      cursorY: 0,
      hasMore: true,
      platformValue: {
        label: "Instagram",
        value: "instagram",
        icon: networkImage("instagram"),
      },
      saveFileType: "csv",
      platformOptions: [
        {
          label: "Instagram",
          value: "instagram",
          icon: networkImage("instagram"),
        },
        {
          label: "YouTube",
          value: "youtube",
          icon: networkImage("youtube"),
        },
        {
          label: "TikTok",
          value: "tiktok",
          icon: networkImage("tiktok"),
        },
      ],
    };
    this.filterOptions = debounce(this.filterOptions.bind(this), 200);
    this.getDropdownOptions = debounce(this.getDropdownOptions.bind(this), 300);
  }
  componentDidMount() {
    this.props.getAuthIntegrations();
    this.props.checkSubscription();
    if (this.props.savedListSearchFilters) {
      let updatedState = calculateInitialStateFromSavedFiltersList(
        this.state,
        this.props.savedListSearchFilters
      );
      this.setState(updatedState, () => {
        this.loadData(updatedState.showRelevance);
      });
    } else {
      if (!this.props.dontCallInfluencersAPI) {
        this.loadData(null, true);
      }
    }

    let searchObj = { types: "country" };

    this.props.getLocationsBasedOnFilter("initial", searchObj);
    this.props.getUniversitiesBasedOnFilter("university", {});
    this.props.getFilteredBrands("initial");
    this.props.getFilteredLookalikes("initial");
    this.props.clearPreviouslyExportedIds();
    this.props.getFilteredInterests("initial");

    window.addEventListener("scroll", this.handleDisableScroll);
    window.addEventListener("resize", this.calculateWindowWidth);
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.exportsErrorMSG && this.props.exportsErrorMSG) {
      this.setState({
        hasMore: false,
      });
    }
  }
  calculateWindowWidth = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };
  handleDisableScroll = (event) => {
    if (this.props.disableScroll) {
      window.scroll(this.state.cursorX, this.state.cursorY);
    }
  };
  componentWillUnmount() {
    let searchObj = {};
    let clearFields = true;
    this.props.getInfluencerRecords(searchObj, clearFields, null);
    this.props.clearSavedListFilters();
    window.removeEventListener("resize", this.calculateWindowWidth);
    window.removeEventListener("scroll", this.handleDisableScroll);
  }

  loadData = (isCloudTagsAPI, isDefaultSearch) => {
    this.setState({
      currentPage: 1,
    });
    let searchObj = calculateSearchObj(this.state);
    searchObj.isDefaultSearch = !!isDefaultSearch;
    searchObj.paging = {
      limit: this.state.pageSize.value,
    };
    this.props.getInfluencerRecords(searchObj, null, isCloudTagsAPI);

    let locationObj =
      this.state.platformValue.value === "tiktok" ? { types: "country" } : {};
    this.props.getLocationsBasedOnFilter("initial", locationObj);

    let filtersObj = {
      data: calculateDataForFilters(this.state),
      filters: searchObj,
    };
    this.props.saveListSearchFilters(filtersObj);
  };
  handleNoOfInfluencersChange = (input) => {
    if (input.value) {
      if (input.value === "custom") {
        this.setState({
          noOfInfluencersNeeded: "",
          noOfInfluencersNeededInDropdown: null,
          influencerNeededType: "textField",
        });
      } else {
        this.setState({
          noOfInfluencersNeeded: "",
          noOfInfluencersNeededInDropdown: input,
          influencerNeededType: "dropdown",
        });
      }
    }
  };

  copyFilters = (isMultiSelect, from, to) => {
    this.setState(
      (prevState) => {
        let modifiedTo = prevState[to];
        if (isMultiSelect) {
          (prevState[from] || []).forEach((option) => {
            if (
              modifiedTo.findIndex((item) => item.value === option.value) < 0
            ) {
              modifiedTo.push(option);
            }
          });
        } else {
          modifiedTo = { ...prevState[from] };
        }

        return {
          [to]: modifiedTo,
        };
      },
      () => this.loadData()
    );
  };
  toggleUnlockAccountsModal = () => {
    this.setState((prevState) => ({
      openUnlockAccountsModal: !prevState.openUnlockAccountsModal,
      influencerNeededType: "dropdown",
    }));
  };

  goToSubscription = (e) => {
    e.preventDefault();
    if (
      this.props.checkoutSessionDetails &&
      this.props.checkoutSessionDetails._id
    ) {
      window.open(
        `${CHECKOUT_APP_EXTERNAL_URL}/?session=${this.props.checkoutSessionDetails._id}`,
        "_self"
      );
    } else {
      this.props.createCheckoutSession();
    }
  };

  handleBioChange = (input) => {
    input.persist();
    this.setState({
      bio: input.target.value,
    });
  };

  handleKeywordsChange = (value) => {
    this.setState(
      {
        keywords: value,
        sortColumn: "keywords",
      },
      () => this.loadData()
    );
  };

  handleUsernameChange = (value) => {
    this.setState(
      {
        username: value.username || value.custom_name,
      },
      () => this.loadData()
    );
  };

  filterOptions = (optionListName, type, value) => {
    switch (type) {
      case "brand":
        if (value && value.trim()) {
          let q = value.trim();
          this.props.getFilteredBrands(optionListName, q);
        }
        break;
      case "lookalike":
        if (value && value.trim()) {
          let q = value.trim();
          this.props.getFilteredLookalikes(
            optionListName,
            q,
            this.state.platformValue?.value
          );
        }
        break;
      case "interests":
        if (value && value.trim()) {
          let q = value.trim();
          this.props.getFilteredInterests(optionListName, q);
        }
        break;
      default:
        return null;
    }
  };

  clearBioValue = () => {
    this.setState(
      {
        bio: "",
      },
      () => this.loadData()
    );
  };

  clearKeywordsValue = () => {
    this.setState(
      {
        keywords: "",
      },
      () => this.loadData()
    );
  };

  clearUsernameValue = () => {
    this.setState(
      {
        username: "",
      },
      () => this.loadData()
    );
  };

  clearEmailMatch_id = () => {
    this.setState(
      {
        emailMatch_id: null,
      },
      () => this.loadData()
    );
  };
  clearCreatorList_id = () => {
    this.setState(
      {
        creatorList_id: null,
      },
      () => this.loadData()
    );
  };
  clearRelevance = (item) => {
    let relevance = this.state.relevance;
    relevance.splice(
      relevance.findIndex((itm) => itm === item),
      1
    );
    this.setState(
      {
        relevance: relevance,
        showRelevance: relevance.length ? true : false,
      },
      () => {
        if (!relevance.length) {
          this.props.getCloudTags(null, true);
        }
        this.loadData(this.state.showRelevance);
      }
    );
  };
  handleInfluencerChange = (input) => {
    input.persist();
    this.setState({
      noOfInfluencersNeeded: input.target.value.replace(/[^0-9]/, "")
        ? parseInt(input.target.value.replace(/[^0-9]/, ""), 10) > 100
          ? 100
          : parseInt(input.target.value.replace(/[^0-9]/, ""), 10) === 0
          ? 1
          : input.target.value.replace(/[^0-9]/, "")
        : "",
    });
  };

  handleBioBlur = (input) => {
    input.persist();
    this.setState(
      {
        bio: input.target.value,
      },
      () => {
        this.loadData();
      }
    );
  };

  handleEnterKeyForBio = (event) => {
    if (event.charCode === 13) {
      this.setState(
        {
          bio: event.target.value,
        },
        () => {
          this.loadData();
        }
      );
    }
  };

  handleAnalyze = (user) => {
    let searchObj = {
      username: user.user_id,
      platform: this.state.platformValue && this.state.platformValue.value,
    };
    let toggleViewReportModal = false;
    let newTab = null; // window.open("/loading", "_blank");
    this.props.getInfluencerProfileDataBySearch(
      searchObj,
      null,
      this.props.loginUser && this.props.loginUser.id,
      toggleViewReportModal,
      newTab
    );
  };
  getLoactionBasedOnFilterValue = (filterName, filterValue) => {
    if (filterValue && filterValue.trim().length) {
      let searchObj = {
        search: filterValue && filterValue.trim(),
        types:
          this.state.platformValue.value === "tiktok"
            ? "country"
            : "city,country,state",
      };

      this.props.getLocationsBasedOnFilter(filterName, searchObj);
    }
  };
  getUniversityBasedOnFilterValue = (filterValue) => {
    if (filterValue && filterValue.trim().length) {
      let q = filterValue && filterValue.trim();
      this.props.getUniversitiesBasedOnFilter("university", { q });
    } else {
      this.props.getUniversitiesBasedOnFilter("university", {});
    }
  };

  toggleFilter = (filterKey) => {
    this.setState((prevState) => {
      let filters = prevState.filters;
      filters[filterKey] = !filters[filterKey];
      return {
        filters: filters,
      };
    });
  };

  toggleEditColumn = () => {
    this.setState({
      editColumnsMenuOpen: !this.state.editColumnsMenuOpen,
    });
  };

  toggleSort = () => {
    this.setState({
      sortMenuOpen: !this.state.sortMenuOpen,
    });
  };

  handleEditcolumns = (input, radioButtonValueOfState, label) => {
    input.persist();
    let selectedThreeTableColumns = this.state.selectedThreeTableColumns;
    if (input.target.name) {
      if (!input.target.checked) {
        selectedThreeTableColumns.splice(
          selectedThreeTableColumns.findIndex(
            (obj) =>
              obj.value === input.target.name &&
              obj.radioButtonValueOfState === radioButtonValueOfState
          ),
          1
        );
      } else {
        if (
          selectedThreeTableColumns.findIndex(
            (obj) =>
              obj.value === input.target.name &&
              obj.radioButtonValueOfState === radioButtonValueOfState
          ) < 0
        ) {
          selectedThreeTableColumns.push({
            label: label,
            value: input.target.name,
            radioButtonValueOfState: radioButtonValueOfState,
          });
        }
      }
    }
    this.setState({
      selectedThreeTableColumns: selectedThreeTableColumns,
    });
  };

  handleOtherFiltersChange = (input) => {
    input.persist();
    let otherFilters = this.state.otherFilters;
    otherFilters[input.target.name] = input.target.checked;
    this.setState(
      {
        otherFilters: otherFilters,
      },
      () => this.loadData()
    );
  };
  handleAccountTypeFiltersChange = (input, label, radioButtonValueOfState) => {
    input.persist();
    let accountTypeFilters = this.state.accountTypeFilters;
    let allSelectedFilters = this.state.allSelectedFilters;
    if (
      allSelectedFilters.findIndex(
        (option) =>
          option.radioButtonValueOfState === radioButtonValueOfState &&
          option.value === label
      ) >= 0
    ) {
      // same radio-group option already present in array
      let oldIndex = allSelectedFilters.findIndex(
        (option) =>
          option.radioButtonValueOfState === radioButtonValueOfState &&
          option.value === label
      );
      allSelectedFilters.splice(oldIndex, 1, {
        label: label,
        value: label,
        radioButtonValueOfState: radioButtonValueOfState,
      });
    } else {
      // fresh radio-group option
      allSelectedFilters.push({
        label: label,
        value: label,
        radioButtonValueOfState: radioButtonValueOfState,
      });
    }
    accountTypeFilters[
      this.state.accountTypeFiltersOptions.find(
        (obj) => obj.value === parseInt(input.target.name)
      ).type
    ] = input.target.checked;
    this.setState(
      {
        accountTypeFilters: accountTypeFilters,
        allSelectedFilters: allSelectedFilters,
      },
      () => this.loadData()
    );
  };

  handleRadioButtonChange = (input, radioButtonValueOfState, label) => {
    input.persist();
    let allSelectedFilters = this.state.allSelectedFilters;
    let selectedThreeTableColumns = this.state.selectedThreeTableColumns;
    if (
      allSelectedFilters.findIndex(
        (option) => option.radioButtonValueOfState === radioButtonValueOfState
      ) >= 0
    ) {
      // same radio-group option already present in array
      let oldIndex = allSelectedFilters.findIndex(
        (option) => option.radioButtonValueOfState === radioButtonValueOfState
      );
      allSelectedFilters.splice(oldIndex, 1, {
        label: label,
        value: input.target.value,
        radioButtonValueOfState: radioButtonValueOfState,
      });
      if (
        selectedThreeTableColumns.findIndex(
          (option) => option.radioButtonValueOfState === radioButtonValueOfState
        ) >= 0
      ) {
        selectedThreeTableColumns.splice(oldIndex, 1, {
          label: label,
          value: input.target.value,
          radioButtonValueOfState: radioButtonValueOfState,
        });
      }
    } else {
      // fresh radio-group option
      allSelectedFilters.push({
        label: label,
        value: input.target.value,
        radioButtonValueOfState: radioButtonValueOfState,
      });
    }

    this.setState(
      {
        [radioButtonValueOfState]: input.target.value,
        allSelectedFilters: allSelectedFilters,
        selectedThreeTableColumns: selectedThreeTableColumns,
      },
      () => {
        // make api call

        this.loadData();
      }
    );
  };

  handleMultipleDropdownChange = (selectedValues, filterName) => {
    let allSelectedFilters = this.state.allSelectedFilters;
    (selectedValues || []).map((value) => {
      if (
        allSelectedFilters.findIndex(
          (option) =>
            option.value === value.value.toString() &&
            option.radioButtonValueOfState === filterName + "Values"
        ) < 0
      ) {
        return allSelectedFilters.push({
          label: value.label,
          value: value.value.toString(),
          radioButtonValueOfState: filterName + "Values",
        });
      } else {
        return null;
      }
    });
    this.setState(
      {
        [filterName + "Values"]: (selectedValues || []).map((item) => ({
          label: item.label || item.text,
          value: item.value,
          weight:
            item.weight && item.weight.value
              ? item.weight
              : { label: "> 10%", value: 0.1 },
        })),
        allSelectedFilters: allSelectedFilters,
      },
      () => this.loadData()
    );
  };
  handleSingleSelectWeight = (option, dropdownName, index) => {
    let dropDownArray = this.state[dropdownName];
    if (dropDownArray && dropDownArray[index]) {
      dropDownArray[index].weight = option;
      this.setState(
        {
          [dropdownName]: dropDownArray,
        },
        () => this.loadData()
      );
    }
  };
  handleSingleSelectChange = (option, weightName, isWeight) => {
    let { allSelectedFilters } = this.state;

    let TEXT = option && option.label;
    let rangeFilter =
      weightName === "audiencesAgeLeft" ||
      weightName === "audiencesAgeRight" ||
      weightName === "influencersAgeLeft" ||
      weightName === "influencersAgeRight" ||
      weightName === "followersFrom" ||
      weightName === "followersTo" ||
      weightName === "viewsFrom" ||
      weightName === "viewsTo" ||
      weightName === "reelsFrom" ||
      weightName === "reelsTo" ||
      weightName === "engagementsInfluencersFrom" ||
      weightName === "engagementsInfluencersTo";
    let isLeftValue =
      weightName === "audiencesAgeLeft" ||
      weightName === "influencersAgeLeft" ||
      weightName === "followersFrom" ||
      weightName === "viewsFrom" ||
      weightName === "reelsFrom" ||
      weightName === "engagementsInfluencersFrom";
    if (isWeight !== true) {
      if (rangeFilter) {
        if (
          allSelectedFilters.findIndex(
            (option) =>
              option.radioButtonValueOfState.split(/[A-Z]/)[0] ===
              weightName.split(/[A-Z]/)[0]
          ) >= 0
        ) {
          // same option already present in array
          let oldIndex = allSelectedFilters.findIndex(
            (option) =>
              option.radioButtonValueOfState.split(/[A-Z]/)[0] ===
              weightName.split(/[A-Z]/)[0]
          );

          let oldLeftValue = allSelectedFilters[oldIndex].value
            .split("-")[0]
            .split(/:\s/)
            .pop();

          let oldRightValue = allSelectedFilters[oldIndex].value
            .split("-")
            .pop();

          let value = `${weightName.split(/[A-Z]/)[0]}: ${
            isLeftValue
              ? `${option.value ? TEXT : ""}-${oldRightValue}`
              : `${oldLeftValue}-${option.value ? TEXT : ""}`
          }`;
          value = value.slice(0, 1).toUpperCase() + value.slice(1);

          allSelectedFilters.splice(oldIndex, 1, {
            label: value,
            value: value,
            radioButtonValueOfState: weightName,
          });
        } else {
          // fresh  option
          let value = `${weightName.split(/[A-Z]/)[0]}: ${
            isLeftValue
              ? `${option.value ? TEXT : ""}-`
              : `-${option.value ? TEXT : ""}`
          }`;
          value = value.slice(0, 1).toUpperCase() + value.slice(1);

          allSelectedFilters.push({
            label: value,
            value: value,
            radioButtonValueOfState: weightName,
          });
        }
      } else {
        if (
          allSelectedFilters.findIndex(
            (option) => option.radioButtonValueOfState === weightName
          ) >= 0
        ) {
          // same option already present in array
          let oldIndex = allSelectedFilters.findIndex(
            (option) => option.radioButtonValueOfState === weightName
          );
          allSelectedFilters.splice(oldIndex, 1, {
            label: TEXT,
            value: option && option.value,
            radioButtonValueOfState: weightName,
          });
        } else {
          // fresh  option
          allSelectedFilters.push({
            label: TEXT,
            value: option && option.value,
            radioButtonValueOfState: weightName,
          });
        }
      }
    }
    this.setState(
      {
        [weightName]:
          option && option.value ? { label: TEXT, value: option.value } : null,
        allSelectedFilters: allSelectedFilters,
      },
      () => {
        if (
          (weightName === "audiencesAgeLeft" ||
            weightName === "audiencesAgeRight") &&
          (!option || !option.value)
        ) {
          this.setState(
            {
              audiencesAgeWeight: { label: "> 10%", value: 0.1 },
            },
            () => this.loadData()
          );
        } else {
          // make api call
          this.loadData();
        }
      }
    );
  };
  handlePageSizeChange = (option) => {
    this.setState(
      {
        pageSize: option,
      },
      () => this.loadData()
    );
  };
  removeRadioFilters = (radiofilterName, removeWeight) => {
    let { selectedThreeTableColumns, allSelectedFilters } = this.state;
    if (
      allSelectedFilters.findIndex(
        (option) => option.radioButtonValueOfState === radiofilterName
      ) >= 0
    ) {
      // remove radio-group option already present in all-selected-filters array
      let oldFilterIndex = allSelectedFilters.findIndex(
        (option) => option.radioButtonValueOfState === radiofilterName
      );
      allSelectedFilters.splice(oldFilterIndex, 1);
      // remove radio-group option already present in selectedThreeTableColumns array
      let oldColumnIndex = selectedThreeTableColumns.findIndex(
        (option) => option.radioButtonValueOfState === radiofilterName
      );
      selectedThreeTableColumns.splice(oldColumnIndex, 1);
    }
    this.setState(
      {
        [radiofilterName]: null,
        allSelectedFilters: allSelectedFilters,
        selectedThreeTableColumns: selectedThreeTableColumns,
        sortColumn: "followers",
        sortDesc: true,
      },
      () => {
        if (removeWeight) {
          this.setState(
            {
              [removeWeight]: { label: "> 10%", value: 0.1 },
            },
            () => this.loadData()
          );
        } else {
          //  make api call
          this.loadData();
        }
      }
    );
  };
  removeSingleSelectFilters = (
    singleSelectFilterName,
    removeWeight,
    callBackFunction
  ) => {
    let { selectedThreeTableColumns, allSelectedFilters } = this.state;
    if (
      allSelectedFilters.findIndex(
        (option) => option.radioButtonValueOfState === singleSelectFilterName
      ) >= 0
    ) {
      // remove option already present in all-selected-filters array
      let oldFilterIndex = allSelectedFilters.findIndex(
        (option) => option.radioButtonValueOfState === singleSelectFilterName
      );
      allSelectedFilters.splice(oldFilterIndex, 1);
      // remove option already present in selectedThreeTableColumns array
      let oldColumnIndex = selectedThreeTableColumns.findIndex(
        (option) => option.radioButtonValueOfState === singleSelectFilterName
      );
      selectedThreeTableColumns.splice(oldColumnIndex, 1);
    }
    this.setState(
      {
        [singleSelectFilterName]: null,
        selectedThreeTableColumns: selectedThreeTableColumns,
        allSelectedFilters: allSelectedFilters,
        sortColumn: "followers",
        sortDesc: true,
      },
      () => {
        if (removeWeight) {
          this.setState(
            {
              [removeWeight]: { label: "> 10%", value: 0.1 },
            },
            () => {
              if (callBackFunction && typeof callBackFunction == "function") {
                callBackFunction();
              }
              this.loadData();
            }
          );
        } else {
          // make api call
          if (callBackFunction && typeof callBackFunction == "function") {
            callBackFunction();
          }
          this.loadData();
        }
      }
    );
  };
  removeMultipleDropdownFilters = (option, filterName, weightName) => {
    let filterArray = this.state[filterName];
    let index = filterArray.findIndex(
      (values) => values.value === option.value
    );
    filterArray.splice(index, 1);
    let allSelectedFilters = this.state.allSelectedFilters;
    let selectedThreeTableColumns = this.state.selectedThreeTableColumns;
    // remove option already present in all-selected-filters array
    if (
      allSelectedFilters.findIndex(
        (opt) =>
          opt.value === option.value.toString() &&
          opt.radioButtonValueOfState === filterName
      ) >= 0
    ) {
      allSelectedFilters.splice(
        allSelectedFilters.findIndex(
          (opt) =>
            opt.value === option.value.toString() &&
            opt.radioButtonValueOfState === filterName
        ),
        1
      );
    }
    // remove option already present in selectedThreeTableColumns array
    let oldColumnIndex = selectedThreeTableColumns.findIndex(
      (opt) =>
        opt.value === option.value.toString() &&
        opt.radioButtonValueOfState === filterName
    );

    selectedThreeTableColumns.splice(oldColumnIndex, 1);
    this.setState(
      {
        [filterName]: filterArray,
        allSelectedFilters: allSelectedFilters,
        selectedThreeTableColumns: selectedThreeTableColumns,
        sortColumn: "followers",
        sortDesc: true,
      },
      () => {
        if (weightName) {
          this.setState(
            (prevState) => ({
              [weightName]: filterArray.length
                ? prevState[weightName]
                : { label: "> 10%", value: 0.1 },
            }),
            () => this.loadData()
          );
        } else {
          // make api call
          this.loadData();
        }
      }
    );
  };
  removeOtherFilters = (otherFilterName) => {
    let otherFilters = this.state.otherFilters;
    otherFilters[otherFilterName] = false;
    this.setState(
      {
        otherFilters: otherFilters,
      },
      () => this.loadData()
    );
  };
  removeAccountTypeFilters = (filterName, radioButtonValueOfState) => {
    let selectedThreeTableColumns = this.state.selectedThreeTableColumns;
    let accountTypeFilters = this.state.accountTypeFilters;
    let allSelectedFilters = this.state.allSelectedFilters;
    if (
      allSelectedFilters.findIndex(
        (option) =>
          option.radioButtonValueOfState === radioButtonValueOfState &&
          option.value === filterName
      ) >= 0
    ) {
      // same radio-group option already present in array
      let oldIndex = allSelectedFilters.findIndex(
        (option) =>
          option.radioButtonValueOfState === radioButtonValueOfState &&
          option.value === filterName
      );
      allSelectedFilters.splice(oldIndex, 1);
    }
    accountTypeFilters[filterName] = false;
    if (
      selectedThreeTableColumns.findIndex(
        (option) =>
          option.radioButtonValueOfState === radioButtonValueOfState &&
          option.value === filterName
      ) >= 0
    ) {
      let oldIndex = selectedThreeTableColumns.findIndex(
        (option) =>
          option.radioButtonValueOfState === radioButtonValueOfState &&
          option.value === filterName
      );
      selectedThreeTableColumns.splice(oldIndex, 1);
    }
    this.setState(
      {
        accountTypeFilters: accountTypeFilters,
        selectedThreeTableColumns: selectedThreeTableColumns,
        sortColumn: "followers",
        sortDesc: true,
      },
      () => this.loadData()
    );
  };
  setSortColumn = (colObj) => {
    this.setState(
      {
        sortColumn: colObj.value,
        sortDesc: colObj.desc ?? true,
      },
      () => this.loadData()
    );
  };
  handleSubmit = () => {
    this.setState(
      {
        showRelevance:
          this.state.relevance && this.state.relevance.length ? true : false,
      },
      () => {
        this.loadData(this.state.relevance && this.state.relevance.length);
      }
    );
  };
  getDropdownOptions = (value, clearFields = false) => {
    let searchObj = {
      q: value.trim(),
      platform: this.state.platformValue.value,
    };

    if (value.trim().length <= 0) {
      clearFields = true;
    }

    this.props.getTopicTags(searchObj, clearFields);
    this.props.getAvailableAccounts(searchObj, clearFields);
  };
  handleRelevanceChange = (values) => {
    values = [...(values || []), ...this.state.relevance];

    this.setState(
      {
        relevance: values,
      },
      () => {
        if (!values || !values.length) {
          this.setState(
            {
              showRelevance: false,
            },
            () => this.loadData()
          );
        } else if (this.state.relevance && this.state.relevance.length) {
          this.setState(
            {
              showRelevance: true,
            },
            () => this.loadData(true)
          );
        }
      }
    );
  };

  clearTagsError = () => {
    this.props.clearTagsErrorMSG();
  };
  clearExportsErrorMSG = () => {
    let searchObj = calculateSearchObj(this.state);
    this.props.clearExportsErrorMSGAction(
      searchObj,
      this.state.showRelevance ? true : false
    );
  };

  clearReportsErrorMessage = () => {
    this.props.clearReportsErrorMsg();
  };
  fetchMoreData = (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
    if (
      this.props.influencerRecords &&
      this.props.influencerRecords.users &&
      this.props.influencerRecords.users.length &&
      !this.props.searchingInfluencers &&
      !this.props.exportsErrorMSG
    ) {
      let searchObj = calculateSearchObj(this.state);
      let postData = searchObj;
      postData.data = calculateDataForFilters(this.state);
      postData.paging = {
        limit: this.state.pageSize.value,
        skip: (pageNumber - 1) * this.state.pageSize.value,
      };
      this.setState(
        {
          cursorX: window.scrollX,
          cursorY: window.scrollY,
        },
        () => this.props.getInfluencerRecords(postData)
      );
    }
  };

  handleUnlockMoreSubmit = () => {
    let searchObj = calculateSearchObj(this.state);
    let postData = searchObj;
    postData.data = calculateDataForFilters(this.state);
    postData.paging = {
      limit:
        this.state.influencerNeededType === "dropdown"
          ? (this.state.noOfInfluencersNeededInDropdown &&
              this.state.noOfInfluencersNeededInDropdown.value) ||
            0
          : this.state.noOfInfluencersNeeded &&
            parseInt(this.state.noOfInfluencersNeeded, 10)
          ? parseInt(this.state.noOfInfluencersNeeded, 10)
          : 0,
      skip:
        this.props.showAllRecords &&
        this.props.influencerRecords.users &&
        this.props.influencerRecords.users.length
          ? this.props.influencerRecords.users.length
          : 0,
    };
    if (
      (this.state.influencerNeededType === "dropdown" &&
        this.state.noOfInfluencersNeededInDropdown &&
        this.state.noOfInfluencersNeededInDropdown.value) ||
      (this.state.influencerNeededType === "textField" &&
        this.state.noOfInfluencersNeeded)
    ) {
      this.setState(
        {
          openUnlockAccountsModal: false,
          cursorX: window.scrollX,
          cursorY: window.scrollY,
        },
        () => this.props.unlockMoreResultsAction(postData)
      );
    }
  };

  isIncludeContact = (input) => {
    this.setState({
      includeContact: true,
    });
  };

  isRemoveDuplicates = (input) => {
    this.setState({
      removeDuplicates: input.target.checked,
    });
  };

  updateExportTotal = (total) => {
    let totalExports = total.target.value;
    if (
      this.props.influencerRecords &&
      this.props.influencerRecords.total < totalExports
    ) {
      totalExports = this.props.influencerRecords.total;
    }

    this.setState({
      exportTotal: totalExports,
    });
  };

  resetExportTotal = () => {
    this.setState({
      exportTotal: "",
      saveFileType: "csv",
      removeDuplicates: false,
      includeContact: true,
    });
  };

  handleExportData = () => {
    if (/*this.props.showAllRecords &&*/ this.props.influencerRecords) {
      let searchObj = calculateSearchObj(this.state);
      let postData = searchObj;
      postData.data = calculateDataForFilters(this.state);
      postData.paging = {
        limit: this.state.exportTotal,
        /*this.props.influencerRecords &&
                  this.props.influencerRecords.users &&
                  this.props.influencerRecords.users.length &&
                  this.props.showAllRecords
                    ? this.props.influencerRecords.users.length
                    : 0*/
      };

      postData.include_contact = this.state.includeContact;
      // postData.remove_duplicates = this.state.removeDuplicates;
      postData.excludePreviouslyExported = this.state.removeDuplicates;
      postData.export_type = this.state.saveFileType;
      this.setState(
        {
          cursorX: window.scrollX,
          cursorY: window.scrollY,
        },
        () => this.props.postForExportCSV(postData)
      );
    }
  };
  handlePlatformChange = (input) => {
    let defaultColumns = [];
    let accountTypeFiltersOptions = [...accountTypeFilterOptions];
    tableColumns.map((option) => defaultColumns.push(option));
    this.props.getCloudTags(null, true);
    this.props.getFilteredLookalikes("initial");
    let otherFilters = {};
    (otherFiltersOptions || []).map(
      (filter) => (otherFilters[filter.value] = false)
    );

    this.setState(
      {
        platformValue: input,
        relevance: [],
        showRelevance: false,
        audienceLocationValues: [],
        influencerLocationValues: [],
        universityValue: null,
        audienceLanguage: null,
        influencerLanguage: null,
        audienceBrandsValues: [],
        influencerBrandsValues: [],
        audienceLookalikeValue: "",
        influencerLookalikeValue: "",
        influencerPartnershipValues: [],
        audienceInterestsValues: [],
        influencerInterestsValues: [],
        bio: "",
        username: "",
        keywords: "",
        genderAudience: null,
        genderInfluencer: null,
        followersGrowth: null,
        likesGrowth: null,
        viewsGrowth: null,
        audiencesAgeLeft: null,
        audiencesAgeRight: null,
        influencersAgeLeft: null,
        influencersAgeRight: null,
        followersFrom: null,
        followersTo: null,
        viewsFrom: null,
        viewsTo: null,
        reelsFrom: null,
        reelsTo: null,
        engagementsInfluencersFrom: null,
        engagementsInfluencersTo: null,
        engagementRate: null,
        contactsValues: [],
        accountTypeFiltersOptions: accountTypeFiltersOptions,
        audienceType: null,
        lastPost: false,
        otherFilters: otherFilters,
      },
      () => this.loadData()
    );
  };
  toggleViewReportModalFunction = (e) => {
    this.props.toggleViewModal();
  };

  openNewTab = (e) => {
    let url = this.props.accountOverviewDetails.report_info.report_id;
    var popUp = window.open(`/reports/${url}`, "_blank");
    if (popUp) {
      this.toggleViewReportModalFunction();
    }
  };
  clearContinueWithTrialAccessSuccessMsg = () => {
    this.props.clearContinueWithTrialAccessSuccessMsgAction();
  };

  handleChangeFile = (file) => {
    if (
      this.props.authIntegration?.integrations?.find(
        (integration) => integration.type === file
      ).oauth
    ) {
      this.setState({
        saveFileType: file,
      });
    }
  };

  render() {
    const {
      pages: { discovery },
      filters: {
        tooltip,
        categories: {
          psychographics,
          demographics,
          geographics,
          performance,
          other,
        },
        disabled,
      },
      providers: { pathfix },
    } = settings;

    const pageTotal = 100;
    /*const notEnoughRoundOffedExportsCredits =
          this.state.includeContact &&
          this.props.userBalanceData &&
          this.props.userBalanceData &&
          this.props.influencerRecords &&
          this.props.influencerRecords.users &&
          this.props.influencerRecords.users.length &&
          Math.ceil(this.props.influencerRecords.users.length / 2) >
            (this.props.userBalanceData.exports || 0)
            ? true
            : false;*/

    /*const exportsCreditsRequired =
          this.state.influencerNeededType === "dropdown" &&
          this.state.noOfInfluencersNeededInDropdown
            ? Math.ceil(this.state.noOfInfluencersNeededInDropdown.value / 2)
            : this.state.noOfInfluencersNeeded
            ? Math.ceil(this.state.noOfInfluencersNeeded / 2)
            : "";*/
    //let mainarray = this.props.postForExportCSV;

    return (
      <>
        {this.props.continueWithTrialAccessSuccessMsg
          ? showSuccessMessage(
              this.props.continueWithTrialAccessSuccessMsg,
              this.clearContinueWithTrialAccessSuccessMsg,
              "none"
            )
          : null}
        {this.props.accountOverviewDetails ? (
          <CreatorReportModal
            isOpen={!!this.props.accountOverviewDetails}
            accountOverviewDetails={this.props.accountOverviewDetails}
          />
        ) : null}
        {this.props.toggleViewReportModal ? (
          <ViewReportModal
            openNewTab={this.openNewTab}
            toggleViewReportModal={this.props.toggleViewReportModal}
            toggleViewReportModalFunction={this.toggleViewReportModalFunction}
          />
        ) : null}
        {this.state.openUnlockAccountsModal ? (
          <UnlockAccountsModal
            handleNoOfInfluencersChange={this.handleNoOfInfluencersChange}
            handleUnlockMoreSubmit={this.handleUnlockMoreSubmit}
            toggleUnlockAccountsModal={this.toggleUnlockAccountsModal}
            openUnlockAccountsModal={this.state.openUnlockAccountsModal}
            noOfInfluencersNeeded={this.state.noOfInfluencersNeeded}
            influencerNeededType={this.state.influencerNeededType}
            noOfInfluencersNeededInDropdown={
              this.state.noOfInfluencersNeededInDropdown
            }
            totalSearchResults={
              this.props.influencerRecords && this.props.influencerRecords.total
                ? formatNumbersToCommaSeperated(
                    this.props.influencerRecords.total
                  )
                : ""
            }
            handleInfluencerChange={this.handleInfluencerChange}
            isIncludeContact={this.isIncludeContact}
          />
        ) : null}
        {this.props.reportsErrorMessage
          ? showErrorMessage(
              store,
              this.props.reportsErrorMessage,
              this.clearReportsErrorMessage,
              "none",
              "reportsErrorMessage"
            )
          : null}
        {this.props.tagsErrorMSG
          ? showErrorMessage(
              store,
              this.props.tagsErrorMSG,
              this.clearTagsError,
              "none",
              "tagsErrorMSG"
            )
          : null}
        {this.props.exportsErrorMSG
          ? showErrorMessage(
              store,
              this.props.exportsErrorMSG,
              this.clearExportsErrorMSG,
              "none",
              "exportsErrorMSG"
            )
          : null}
        <Container className="main-site-content discovery">
          <Row>
            <Col className="is-search-block">
              <h1 className="page-title">
                <Trans i18nKey="discovery.title">Discovery</Trans>
              </h1>
            </Col>
            <Col md="4" className="list-btn-col">
              <div
                className="modal fade"
                id="exportModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exportModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header ">
                      <h5 className="modal-title " id="exportModalLongTitle">
                        <span className="export-icon mr-3">
                          <ArrowDownTrayIcon width={25} height={25} />
                        </span>
                        <Trans i18nKey="discovery.export.title">
                          Export Records
                        </Trans>
                      </h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p className="d-flex justify-content-between text-sub">
                        {this.props.influencerRecords &&
                        this.props.influencerRecords.total
                          ? `${formatNumbersToCommaSeperated(
                              this.props.influencerRecords.total
                            )} `
                          : ""}
                        <Trans i18nKey="discovery.export.results">
                          Results
                        </Trans>
                        <span>
                          {this.props && this.props.userBalanceData ? (
                            <span>
                              {formatNumbersToCommaSeperated(
                                this.props.userBalanceData.exports
                              )}{" "}
                              <Trans i18nKey="discovery.export.remaining">
                                Exports Remaining
                              </Trans>
                            </span>
                          ) : (
                            <Trans i18nKey="discovery.export.empty">
                              No Exports Available
                            </Trans>
                          )}
                        </span>
                      </p>
                      <div className="form-group">
                        <Translation>
                          {(t, { i18n }) => (
                            <input
                              type="text"
                              name="exportTotal"
                              id="exportTotal"
                              placeholder={t("discovery.export.placeholder")}
                              value={this.state.exportTotal}
                              onChange={(total) =>
                                this.updateExportTotal(total)
                              }
                              className="styled-textfield center-dropdown form-control"
                            />
                          )}
                        </Translation>
                      </div>
                      {pathfix.enabled && (
                        <div className="connect-account">
                          <Link
                            to="/settings"
                            onClick={() => this.props.history.push("/settings")}
                            data-dismiss="modal"
                          >
                            Manage Integrations
                          </Link>
                        </div>
                      )}
                      <IntegrationSelection
                        state={this.state}
                        history={this.props.history}
                        handleChangeFile={this.handleChangeFile}
                        authIntegration={this.props.authIntegration}
                        userId={this.props.loginUser.id}
                      />
                    </div>
                    <div className="modal-footer">
                      <div className="chek-btn d-flex">
                        {discovery.duplicates && (
                          <div className="form-check pr-5">
                            <label
                              className="form-check-label"
                              htmlFor="removeDuplicates"
                            >
                              <input
                                name="removeDuplicates"
                                id="removeDuplicates"
                                checked={this.state.removeDuplicates}
                                onChange={(input) =>
                                  this.isRemoveDuplicates(input)
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              Exclude Previously Exported Creators
                            </label>
                          </div>
                        )}
                      </div>

                      <Translation>
                        {(t, { i18n }) => (
                          <button
                            onClick={() => {
                              this.handleExportData();
                            }}
                            disabled={
                              !(
                                this.state.exportTotal &&
                                this.state.exportTotal > 0 &&
                                this.state.saveFileType &&
                                this.props.userBalanceData &&
                                this.state.exportTotal <=
                                  this.props.userBalanceData.exports
                              )
                            }
                            data-toggle="modal"
                            data-target="#afterClick"
                            data-dismiss="modal"
                            type="button"
                            className="btn btn-primary export-button"
                          >
                            {t("discovery.export.button")}
                          </button>
                        )}
                      </Translation>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="modal fade"
                id="afterClick"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exportModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content done-pop">
                    <div className="modal-header justify-content-around">
                      <Loader className="social-cloud-loader" />
                    </div>
                    <div className="modal-body text-center mbl-done">
                      <h6 className="export-waiting">
                        <Trans i18nKey="discovery.export.waiting">
                          We are preparing your export!
                        </Trans>
                      </h6>
                      <button
                        type="button"
                        className="mt-5 btn btn-primary export-button"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <Trans i18nKey="discovery.export.done">Done</Trans>
                      </button>
                    </div>
                    <div className="modal-footer"></div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Formik
            onSubmit={this.handleSubmit}
            initialValues={this.props.initialValues}
          >
            {() => (
              <Form className={this.props.blockRecords ? "block-records" : ""}>
                <SearchBoxComponent
                  state={this.state}
                  cloudTags={this.props.cloudTags}
                  topicTags={this.props.topicTags}
                  availableAccounts={this.props.availableAccounts}
                  getDropdownOptions={this.getDropdownOptions}
                  handleKeywordsChange={this.handleKeywordsChange}
                  handleUsernameChange={this.handleUsernameChange}
                  loading={this.props.loading}
                  handlePlatformChange={this.handlePlatformChange}
                />
                <div className="filters-wrap">
                  <Row>
                    {psychographics.enabled && (
                      <Col md={psychographics.column}>
                        <h1 className="filter-title mt-4">
                          <Trans i18nKey="discovery.category.psychographics">
                            Psychographics
                          </Trans>
                        </h1>
                        <Row>
                          <Col className="px-0 col-6">
                            {this.state.platformValue &&
                            (this.state.platformValue.value === "youtube" ||
                              this.state.platformValue.value ===
                                "tiktok") ? null : (
                              <InterestsFilter
                                tooltip={tooltip}
                                blockRecords={this.props.blockRecords}
                                copyFilters={this.copyFilters}
                                toggleFilter={this.toggleFilter}
                                filterOptions={this.filterOptions}
                                handleMultipleDropdownChange={
                                  this.handleMultipleDropdownChange
                                }
                                removeMultipleDropdownFilters={
                                  this.removeMultipleDropdownFilters
                                }
                                handleSingleSelectWeight={
                                  this.handleSingleSelectWeight
                                }
                                filteredAudienceInterestOptions={
                                  this.props.filteredAudienceInterestOptions
                                }
                                filteredInfluencerInterestOptions={
                                  this.props.filteredInfluencerInterestOptions
                                }
                                state={this.state}
                              />
                            )}
                          </Col>
                          <Col className="px-0 col-6">
                            {this.state.platformValue &&
                            (this.state.platformValue.value === "youtube" ||
                              this.state.platformValue.value ===
                                "tiktok") ? null : (
                              <BrandsFilter
                                tooltip={tooltip}
                                copyFilters={this.copyFilters}
                                toggleFilter={this.toggleFilter}
                                filterOptions={this.filterOptions}
                                handleMultipleDropdownChange={
                                  this.handleMultipleDropdownChange
                                }
                                removeMultipleDropdownFilters={
                                  this.removeMultipleDropdownFilters
                                }
                                handleSingleSelectWeight={
                                  this.handleSingleSelectWeight
                                }
                                state={this.state}
                                filteredAudienceBrandOptions={
                                  this.props.filteredAudienceBrandOptions
                                }
                                filteredInfluencerBrandOptions={
                                  this.props.filteredInfluencerBrandOptions
                                }
                              />
                            )}
                          </Col>

                          <Col className="px-0 col-6">
                            <LookalikesFilter
                              tooltip={tooltip}
                              state={this.state}
                              copyFilters={this.copyFilters}
                              toggleFilter={this.toggleFilter}
                              filterOptions={this.filterOptions}
                              handleSingleSelectChange={
                                this.handleSingleSelectChange
                              }
                              removeSingleSelectFilters={
                                this.removeSingleSelectFilters
                              }
                              // handleMultipleDropdownChange={
                              //   this.handleMultipleDropdownChange
                              // }
                              filteredAudienceLookalikeOptions={
                                this.props.filteredAudienceLookalikeOptions
                              }
                              filteredInfluencerLookalikeOptions={
                                this.props.filteredInfluencerLookalikeOptions
                              }
                            />
                          </Col>

                          <Col className="px-0 col-6">
                            <BioFilter
                              tooltip={tooltip}
                              state={this.state}
                              handleBioChange={this.handleBioChange}
                              handleBioBlur={this.handleBioBlur}
                              handleEnterKeyForBio={this.handleEnterKeyForBio}
                              toggleFilter={this.toggleFilter}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}

                    {demographics.enabled && (
                      <Col
                        md={demographics.column}
                        className={discovery.offset ? "offset-md-1" : ""}
                      >
                        <h1 className="filter-title mt-4">
                          <Trans i18nKey="discovery.category.demographics">
                            Demographics
                          </Trans>
                        </h1>
                        <Row>
                          <Col className="px-0 col-6">
                            <AgeFilter
                              tooltip={tooltip}
                              handleSingleSelectChange={
                                this.handleSingleSelectChange
                              }
                              state={this.state}
                              toggleFilter={this.toggleFilter}
                            />
                          </Col>
                          <Col className="px-0 col-6">
                            <GenderFilter
                              tooltip={tooltip}
                              handleSingleSelectChange={
                                this.handleSingleSelectChange
                              }
                              handleRadioButtonChange={
                                this.handleRadioButtonChange
                              }
                              state={this.state}
                              toggleFilter={this.toggleFilter}
                            />
                          </Col>

                          <Col className="px-0 col-6">
                            <LanguageFilter
                              tooltip={tooltip}
                              copyFilters={this.copyFilters}
                              toggleFilter={this.toggleFilter}
                              handleSingleSelectChange={
                                this.handleSingleSelectChange
                              }
                              removeSingleSelectFilters={
                                this.removeSingleSelectFilters
                              }
                              state={this.state}
                            />
                          </Col>
                          {!disabled.includes("ethnicity") && (
                            <Col className="px-0 col-6">
                              {this.state.platformValue &&
                              (this.state.platformValue.value === "youtube" ||
                                this.state.platformValue.value ===
                                  "tiktok") ? null : (
                                <EthnicityFilter
                                  tooltip={tooltip}
                                  handleSingleSelectChange={
                                    this.handleSingleSelectChange
                                  }
                                  handleRadioButtonChange={
                                    this.handleRadioButtonChange
                                  }
                                  state={this.state}
                                  toggleFilter={this.toggleFilter}
                                />
                              )}
                            </Col>
                          )}
                        </Row>
                      </Col>
                    )}

                    {geographics.enabled && (
                      <Col
                        md={geographics.column}
                        className={discovery.offset ? "offset-md-1" : ""}
                      >
                        <h1 className="filter-title mt-4">
                          <Trans i18nKey="discovery.category.geographics">
                            Geographics
                          </Trans>
                        </h1>
                        <Row>
                          <Col md="12" xs="6" className="px-0">
                            <LocationFilter
                              tooltip={tooltip}
                              copyFilters={this.copyFilters}
                              handleSingleSelectWeight={
                                this.handleSingleSelectWeight
                              }
                              toggleFilter={this.toggleFilter}
                              getLoactionBasedOnFilterValue={
                                this.getLoactionBasedOnFilterValue
                              }
                              handleMultipleDropdownChange={
                                this.handleMultipleDropdownChange
                              }
                              removeMultipleDropdownFilters={
                                this.removeMultipleDropdownFilters
                              }
                              audiencesFilteredLoactions={
                                this.props.audiencesFilteredLoactions
                              }
                              influencersFilteredLocations={
                                this.props.influencersFilteredLocations
                              }
                              state={this.state}
                            />
                          </Col>
                          {!disabled.includes("university") && (
                            <Col md="12" xs="6" className="px-0">
                              {this.state.platformValue &&
                              (this.state.platformValue.value === "youtube" ||
                                this.state.platformValue.value ===
                                  "tiktok") ? null : (
                                <UniversityFilter
                                  tooltip={tooltip}
                                  toggleFilter={this.toggleFilter}
                                  removeSingleSelectFilters={
                                    this.removeSingleSelectFilters
                                  }
                                  handleSingleSelectChange={
                                    this.handleSingleSelectChange
                                  }
                                  influencerFilteredUniversities={
                                    this.props.influencerFilteredUniversities
                                  }
                                  getUniversityBasedOnFilterValue={
                                    this.getUniversityBasedOnFilterValue
                                  }
                                  state={this.state}
                                />
                              )}
                            </Col>
                          )}
                        </Row>
                      </Col>
                    )}
                  </Row>

                  <Row className="mb-3">
                    {performance.enabled && (
                      <Col md={performance.column}>
                        <h1 className="filter-title mt-5">
                          <Trans i18nKey="discovery.category.performance">
                            Performance
                          </Trans>
                        </h1>
                        <Row>
                          <Col
                            md={performance.filter_column}
                            xs="6"
                            className="px-0"
                          >
                            <FollowersFilter
                              tooltip={tooltip}
                              handleSingleSelectChange={
                                this.handleSingleSelectChange
                              }
                              state={this.state}
                              toggleFilter={this.toggleFilter}
                            />
                          </Col>
                          {this.state?.platformValue?.value === "instagram" && (
                            <Col
                              md={performance.filter_column}
                              xs="6"
                              className="px-0"
                            >
                              <ReelsFilter
                                tooltip={tooltip}
                                handleSingleSelectChange={
                                  this.handleSingleSelectChange
                                }
                                state={this.state}
                                toggleFilter={this.toggleFilter}
                              />
                            </Col>
                          )}
                          <Col
                            md={performance.filter_column}
                            xs="6"
                            className="px-0"
                          >
                            <EngagementFilter
                              tooltip={tooltip}
                              handleSingleSelectChange={
                                this.handleSingleSelectChange
                              }
                              state={this.state}
                              toggleFilter={this.toggleFilter}
                            />
                          </Col>
                          <Col
                            md={performance.filter_column}
                            xs="6"
                            className="px-0"
                          >
                            <LastPostFilter
                              tooltip={tooltip}
                              handleRadioButtonChange={
                                this.handleRadioButtonChange
                              }
                              state={this.state}
                              toggleFilter={this.toggleFilter}
                            />
                          </Col>
                          {this.state.platformValue &&
                          (this.state.platformValue.value === "youtube" ||
                            this.state.platformValue.value === "tiktok") ? (
                            <Col
                              md={performance.filter_column}
                              xs="6"
                              className="px-0"
                            >
                              <ViewsFilter
                                tooltip={tooltip}
                                handleSingleSelectChange={
                                  this.handleSingleSelectChange
                                }
                                state={this.state}
                                toggleFilter={this.toggleFilter}
                              />
                            </Col>
                          ) : null}
                          {!disabled.includes("growth") && (
                            <Col
                              md={performance.filter_column}
                              xs="6"
                              className="px-0"
                            >
                              <GrowthFilter
                                handleSingleSelectChange={
                                  this.handleSingleSelectChange
                                }
                                handleRadioButtonChange={
                                  this.handleRadioButtonChange
                                }
                                state={this.state}
                                toggleFilter={this.toggleFilter}
                              ></GrowthFilter>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    )}

                    {other.enabled && (
                      <Col
                        md={
                          this.state.platformValue.value !== "instagram"
                            ? other.column - 1
                            : other.column
                        }
                        className={
                          this.state.platformValue.value !== "instagram"
                            ? "offset-md-1"
                            : ""
                        }
                      >
                        <h1 className="filter-title mt-5">
                          <Trans i18nKey="discovery.category.other">
                            Other
                          </Trans>
                        </h1>
                        <Row>
                          <Col md="4" xs="6" className="px-0">
                            {this.state.platformValue &&
                            (this.state.platformValue.value === "youtube" ||
                              this.state.platformValue.value ===
                                "tiktok") ? null : (
                              <AccountTypeFilter
                                tooltip={tooltip}
                                state={this.state}
                                handleAccountTypeFiltersChange={
                                  this.handleAccountTypeFiltersChange
                                }
                                toggleFilter={this.toggleFilter}
                              />
                            )}
                          </Col>
                          <Col md="4" xs="6" className="px-0">
                            {this.state.platformValue &&
                            (this.state.platformValue.value === "youtube" ||
                              this.state.platformValue.value ===
                                "tiktok") ? null : (
                              <AudienceTypeFilter
                                tooltip={tooltip}
                                handleRadioButtonChange={
                                  this.handleRadioButtonChange
                                }
                                state={this.state}
                                toggleFilter={this.toggleFilter}
                              />
                            )}
                          </Col>
                          <Col md="4" xs="6" className="px-0">
                            {this.state.platformValue &&
                            (this.state.platformValue.value === "youtube" ||
                              this.state.platformValue.value ===
                                "tiktok") ? null : (
                              <PartnershipFilter
                                tooltip={tooltip}
                                copyFilters={this.copyFilters}
                                toggleFilter={this.toggleFilter}
                                filterOptions={this.filterOptions}
                                handleMultipleDropdownChange={
                                  this.handleMultipleDropdownChange
                                }
                                removeMultipleDropdownFilters={
                                  this.removeMultipleDropdownFilters
                                }
                                handleSingleSelectWeight={
                                  this.handleSingleSelectWeight
                                }
                                state={this.state}
                                filteredAudienceBrandOptions={
                                  this.props.filteredAudienceBrandOptions
                                }
                                filteredInfluencerBrandOptions={
                                  this.props.filteredInfluencerBrandOptions
                                }
                              />
                            )}
                          </Col>
                          <Col
                            md={
                              this.state.platformValue.value !== "instagram"
                                ? other.contacts_filter + 1
                                : other.contacts_filter
                            }
                            xs="6"
                            className="px-0"
                          >
                            <ContactsFilter
                              tooltip={tooltip}
                              toggleFilter={this.toggleFilter}
                              handleMultipleDropdownChange={
                                this.handleMultipleDropdownChange
                              }
                              removeMultipleDropdownFilters={
                                this.removeMultipleDropdownFilters
                              }
                              handleSingleSelectChange={
                                this.handleSingleSelectChange
                              }
                              state={this.state}
                            />
                          </Col>
                          <Col md="4" xs="6" className="px-0">
                            <OtherFilters
                              tooltip={tooltip}
                              state={this.state}
                              handleOtherFiltersChange={
                                this.handleOtherFiltersChange
                              }
                              toggleFilter={this.toggleFilter}
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                </div>
                <div id="discovery" className="p-0 inline-filters">
                  <div className="active-filters">
                    <ul className="list-inline all-selected-filters">
                      <CommonSelectedFilters
                        state={this.state}
                        toggleFilter={this.toggleFilter}
                        clearRelevance={this.clearRelevance}
                        clearUsernameValue={this.clearUsernameValue}
                        clearKeywordsValue={this.clearKeywordsValue}
                        clearBioValue={this.clearBioValue}
                        clearEmailMatch_id={this.clearEmailMatch_id}
                        clearCreatorList_id={this.clearCreatorList_id}
                        removeMultipleDropdownFilters={
                          this.removeMultipleDropdownFilters
                        }
                        removeSingleSelectFilters={
                          this.removeSingleSelectFilters
                        }
                        removeRadioFilters={this.removeRadioFilters}
                        removeOtherFilters={this.removeOtherFilters}
                        removeAccountTypeFilters={this.removeAccountTypeFilters}
                      />
                    </ul>
                  </div>
                  <RecordsList
                    subscriptionDetails={this.props.subscriptionDetails}
                    tooltip={tooltip}
                    state={this.state}
                    toggleSort={this.toggleSort}
                    setSortColumn={this.setSortColumn}
                    toggleEditColumn={this.toggleEditColumn}
                    handleEditcolumns={this.handleEditcolumns}
                    influencerRecords={this.props.influencerRecords}
                    handleAnalyze={this.handleAnalyze}
                    goToSubscription={this.goToSubscription}
                    blockRecords={this.props.blockRecords}
                    showAllRecords={true}
                    resetExportTotal={() => this.resetExportTotal()}
                    getAuthIntegrations={this.props.getAuthIntegrations}
                    getCreatorLists={this.props.getCreatorLists}
                    addCreatorList={this.props.addCreatorList}
                    updateCreatorList={this.props.updateCreatorList}
                    creatorLists={this.props.creatorLists}
                    dismissCreatorListModal={this.props.dismissCreatorListModal}
                  />
                  {this.props.influencerRecords &&
                    this.props.influencerRecords.total >
                      this.state.pageSize.value && (
                      <Paginator
                        state={this.state}
                        activePage={this.state.currentPage}
                        itemsPerPage={this.state.pageSize.value}
                        totalItems={
                          this.props.influencerRecords.total >
                          this.state.pageSize.value * pageTotal
                            ? this.state.pageSize.value * pageTotal
                            : this.props.influencerRecords.total
                        }
                        handlePageChange={(pageNumber) =>
                          this.fetchMoreData(pageNumber)
                        }
                        handlePageSizeChange={this.handlePageSizeChange}
                      />
                    )}
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    initialValues: { searchFieldValue: "" },
    influencerRecords: state.identification.influencerRecords,
    audiencesFilteredLoactions: (
      state.identification.audiencesFilteredLoactions || []
    ).map((loc) => ({ label: loc.title, value: loc.id })),
    influencersFilteredLocations: (
      state.identification.influencersFilteredLocations || []
    ).map((loc) => ({ label: loc.title, value: loc.id })),
    loading: state.identification.loading,
    blockRecords: state.identification.blockRecords,
    cloudTags: state.identification.cloudTags,
    loginUser: state.login.loginUser,

    tagsErrorMSG: state.identification.tagsErrorMSG,
    reportsErrorMessage: state.reports.reportsErrorMessage,
    dontCallInfluencersAPI: state.identification.dontCallInfluencersAPI,
    savedListSearchFilters: state.userProfile.savedListSearchFilters,
    exportsErrorMSG: state.identification.exportsErrorMSG,
    searchingInfluencers: state.identification.searchingInfluencers,
    showAllRecords: state.identification.showAllRecords,
    influencerFilteredUniversities:
      state.identification.influencerFilteredUniversities &&
      state.identification.influencerFilteredUniversities.data &&
      state.identification.influencerFilteredUniversities.data.map(
        (val, index) => ({
          label: val.text,
          value: index < 9 ? "0" + index + val.value : index + val.value,
        })
      ),

    filteredInfluencerBrandOptions:
      (state.identification.filteredInfluencerBrandOptions &&
        state.identification.filteredInfluencerBrandOptions.data &&
        state.identification.filteredInfluencerBrandOptions.data.map(
          (item) => ({
            label: item.text,
            value: item.value,
          })
        )) ||
      [],

    filteredAudienceBrandOptions:
      (state.identification.filteredAudienceBrandOptions &&
        state.identification.filteredAudienceBrandOptions.data &&
        state.identification.filteredAudienceBrandOptions.data.map((item) => ({
          label: item.text,
          value: item.value,
        }))) ||
      [],

    filteredInfluencerLookalikeOptions:
      (state.identification.filteredInfluencerLookalikeOptions &&
        state.identification.filteredInfluencerLookalikeOptions.data &&
        state.identification.filteredInfluencerLookalikeOptions.data.map(
          (item) => {
            return {
              label: item.username
                ? `@${item.username}`
                : item.fullname ||
                  item.custom_name ||
                  item.user_id ||
                  item.social_id,
              value:
                item.type === "youtube"
                  ? item.user_id ||
                    item.username ||
                    item.social_id ||
                    item.fullname
                  : item.username || item.fullname,
              picture: item.picture,
              network: item.type,
            };
          }
        )) ||
      [],

    filteredAudienceLookalikeOptions:
      (state.identification.filteredAudienceLookalikeOptions &&
        state.identification.filteredAudienceLookalikeOptions.data &&
        state.identification.filteredAudienceLookalikeOptions.data.map(
          (item) => {
            return {
              label: item.username
                ? `@${item.username}`
                : item.fullname ||
                  item.custom_name ||
                  item.user_id ||
                  item.social_id,
              value:
                item.type === "youtube"
                  ? item.user_id ||
                    item.username ||
                    item.social_id ||
                    item.fullname
                  : item.username || item.fullname,
              picture: item.picture,
              network: item.type,
            };
          }
        )) ||
      [],

    filteredAudienceInterestOptions:
      (state.identification.filteredAudienceInterestOptions &&
        state.identification.filteredAudienceInterestOptions.data &&
        state.identification.filteredAudienceInterestOptions.data.map(
          (item) => ({
            label: item.text,
            value: item.value,
          })
        )) ||
      [],

    filteredInfluencerInterestOptions:
      (state.identification.filteredInfluencerInterestOptions &&
        state.identification.filteredInfluencerInterestOptions.data &&
        state.identification.filteredInfluencerInterestOptions.data.map(
          (item) => ({ label: item.text, value: item.value })
        )) ||
      [],
    // previouslyExportedIds: state.login.previouslyExportedIds,
    toggleViewReportModal: state.reports.toggleViewReportModal,
    accountOverviewDetails: state.reports.accountOverviewDetails,
    disableScroll: state.identification.disableScroll,
    continueWithTrialAccessSuccessMsg:
      state.paymentPortal.continueWithTrialAccessSuccessMsg,
    topicTags:
      state.identification.topicTags &&
      state.identification.topicTags.data &&
      state.identification.topicTags.data.length
        ? state.identification.topicTags.data.map((item) => item["tag"])
        : [],
    availableAccounts: state.identification.availableAccounts,
    userBalanceData: state.header.UserBalanceData,
    subscriptionDetails: state.login.subscriptionDetails,
    checkoutSessionDetails: state.login.checkoutSessionDetails,
    authIntegration: state.identification.authIntegration,
    creatorLists: state.creatorLists.lists,
    dismissCreatorListModal: state.creatorLists.dismissModal,
  };
};

// function mapDispatchToProps(dispatch) {
//   // when arguments match, you can pass configuration object, which will
//   // wrap your actions creators with dispatch automatically.
//   return {
//     getUserBalanceData,
//     //fetchCategoriesIfNeeded,
//     //fetchPostsIfNeeded,
//   };
// }

export default connect(mapStateToProps, {
  getInfluencerRecords,
  showLoadingAction,
  getLocationsBasedOnFilter,
  getInfluencerProfileDataBySearch,
  clearReportsErrorMsg,
  signOut,
  clearTagsErrorMSG,
  clearExportsErrorMSGAction,
  checkExportsRemaining,
  postForExportCSV,
  getUniversitiesBasedOnFilter,
  getFilteredBrands,
  getFilteredLookalikes,
  getFilteredInterests,
  clearSavedListFilters,
  saveListSearchFilters,
  unlockMoreResultsAction,
  clearPreviouslyExportedIds,
  toggleViewModal,
  getCloudTags,
  getTopicTags,
  getAvailableAccounts,
  clearContinueWithTrialAccessSuccessMsgAction,
  checkSubscription,
  createCheckoutSession,
  getAuthIntegrations,
  getCreatorLists,
  addCreatorList,
  updateCreatorList,
})(IdentificationComponent);
