import React, { useEffect, useState } from "react";
import settings from "settings";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import {
  CogIcon,
  HomeIcon,
  Bars3Icon,
  XMarkIcon,
  ChartPieIcon,
  ClipboardDocumentIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { connect } from "react-redux";
import { getUserBalanceData } from "./actions/index";
import { RenderButton } from "../../components";
import { signOut } from "../login/actions/index";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import Balance from "./balance";

const MobileHeader = ({ userBalance, getUserBalanceData, signOut, loginUser, ...props }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);

  const {
    pages: { dashboard, discovery, reports },
  } = settings;

  const toggleNavbar = () => setCollapsed(!collapsed);

  useEffect(() => {
    const userId = loginUser && loginUser.id;
    if (userId) {
      getUserBalanceData(userId);
    }
  }, [loginUser, getUserBalanceData]);

  return (
    <>
      <div className="d-xl-none header-container-mobile">
        <Navbar className="mobile-top-nav">
          <NavbarBrand>
            <img
              src={settings.mainbrand}
              alt="homepage"
              style={{
                height: 25,
                width: "auto",
                margin: "5px",
              }}
            />
          </NavbarBrand>
          <NavbarToggler className="me-2" onClick={toggleNavbar}>
            <span className="icon-container">
              {collapsed ? (
                <Bars3Icon
                  className="link-icon"
                  width={25}
                  height={25}
                  strokeWidth={1.5}
                />
              ) : (
                <XMarkIcon
                  className="link-icon"
                  width={25}
                  height={25}
                  strokeWidth={1.5}
                />
              )}
            </span>
          </NavbarToggler>
        </Navbar>
        <Collapse
          id="mobile-sidebar"
          className="mobile-sidebar"
          isOpen={!collapsed}
        >
          <Nav vertical className="link-container">
            <div className="link-wrapper">
              {dashboard.enabled && (
                <NavLink
                  to="/dashboard"
                  activeClassName="active"
                  className="link"
                  onClick={toggleNavbar}
                >
                  <span className="icon-container">
                    <HomeIcon
                      className="link-icon"
                      width={30}
                      height={30}
                      strokeWidth={2}
                    />
                  </span>

                  <span className="sidebar-page">{t("dashboard.title")}</span>
                </NavLink>
              )}
              {discovery.enabled && (
                <NavLink
                  to="/discovery"
                  activeClassName="active"
                  className="link"
                  onClick={toggleNavbar}
                >
                  <span className="icon-container">
                    <MagnifyingGlassIcon
                      className="link-icon"
                      width={30}
                      height={30}
                      strokeWidth={2}
                    />
                  </span>

                  <span className="sidebar-page">{t("discovery.title")}</span>
                </NavLink>
              )}
              {reports.enabled && (
                <NavLink
                  to="/reports"
                  activeClassName="active"
                  className="link"
                  onClick={toggleNavbar}
                >
                  <span className="icon-container">
                    <ChartPieIcon
                      className="link-icon"
                      width={30}
                      height={30}
                      strokeWidth={2}
                    />
                  </span>
                  <span className="sidebar-page">{t("reports.title")}</span>
                </NavLink>
              )}
              <NavLink
                to="/activity"
                activeClassName="active"
                className="link"
                onClick={toggleNavbar}
              >
                <span className="icon-container">
                  <ClipboardDocumentIcon
                    className="link-icon"
                    width={30}
                    height={30}
                    strokeWidth={2}
                  />
                </span>
                <span className="sidebar-page">Activity</span>
              </NavLink>
              <NavLink
                to="/settings"
                activeClassName="active"
                className="link"
                onClick={toggleNavbar}
              >
                <span className="icon-container">
                  <CogIcon
                    className="link-icon"
                    width={30}
                    height={30}
                    strokeWidth={2}
                  />
                </span>
                <span className="sidebar-page">Settings</span>
              </NavLink>
            </div>
            <div className="logout">
              <div className="mb-4">
                <Balance userBalance={userBalance} />
              </div>
              <RenderButton
                outline
                color="danger"
                onClick={() => {
                  toggleNavbar();
                  signOut();
                }}
              >
                {t("auth.sidebar.sign_out")}
              </RenderButton>
            </div>
          </Nav>
        </Collapse>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userBalance: state.header.UserBalanceData,
    subscriptionDetails: state.login.subscriptionDetails,
    loginUser: state.login.loginUser,
  };
};

export default connect(mapStateToProps, {
  getUserBalanceData,
  signOut,
})(MobileHeader);
