import React from "react";
import { useCallback } from "react";

/**
 * A button component that allows copying text to the clipboard.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.textToCopy - The text to be copied to the clipboard.
 * @param {string} [props.classNames=""] - Additional CSS classes for the component.
 * @returns {JSX.Element} The ClipboardWriteButton component.
 */
const ClipboardWriteButton = ({
  textToCopy,
  displayText = textToCopy,
  classNames = "",
  children,
  ...props
}) => {
  const fallbackCopyHandler = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    const success = document.execCommand("copy");
    document.body.removeChild(textArea);
    return success;
  };

  const copyHandler = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch (err) {
      // TODO: log when and why someone is unable to use the new clipboard API
      if (fallbackCopyHandler(textToCopy)) {
        return;
      }
    }
  }, [textToCopy]);

  return (
    <div
      onClick={copyHandler}
      className={`clipboard-write-btn ${classNames}`}
    >
      {children}
    </div>
  );
};

export default ClipboardWriteButton;
