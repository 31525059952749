import React from 'react';

function ProfileEmail({ contacts }) {
  const emailObject = contacts.find(obj => obj.type === 'email');

  if (!emailObject) {
    return null;
  }

  return (
    <span className={emailObject.__II_hide_email ? 'blurred-email' : ''}>
      {emailObject.__II_hide_email ? "upgrade+for@emails" : emailObject.value}
    </span>
  );
}

export default ProfileEmail;
