import React, { useState, useEffect } from "react";
import settings from "settings";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import useDarkMode from "use-dark-mode";
import { NavLink } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import {
  ChartPieIcon,
  ClipboardDocumentIcon,
  CogIcon,
  HomeIcon,
  PhotoIcon,
  EnvelopeIcon,
  MagnifyingGlassIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { PhoneIcon } from "@heroicons/react/20/solid";
import { connect } from "react-redux";
import { generateBillingPortal, signOut } from "../login/actions/index";
import { getUserBalanceData } from "./actions/index";
import { createCheckoutSession } from "../login/actions/index";
import CalendlyEmbed from "components/calendly/CalendlyEmbed";
import Balance from "./balance";

const Sidebar = (props) => {
  const { t } = useTranslation();
  const darkModeConfig = {
    classNameDark: "theme-dark",
    classNameLight: "theme-light",
  };
  const darkMode = useDarkMode(false, darkModeConfig);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [calendlyOpen, setCalendlyOpen] = useState(false);
  const loginUser = props.loginUser;
  const { getUserBalanceData } = props;

  useEffect(() => {
    const userId = loginUser && loginUser.id;
    setProfileDropdownOpen(false);
    if (userId) {
      getUserBalanceData(userId);
    }
  }, [loginUser, getUserBalanceData]);

  const {
    pages: {
      dashboard,
      discovery,
      reports,
      creator_lists,
      campaign_results,
      follower_list,
      campaign_manager,
    },
    providers: { calendly },
  } = settings;

  const toggleSettings = () => {
    if (!props.isExpanded) {
      props.handleToggler(true);
    }
    setProfileDropdownOpen(!profileDropdownOpen);
  };

  const handleCalendlyPopup = (value) => {
    setCalendlyOpen(value);
  };

  useEffect(() => {
    if (!props.isExpanded) {
      setProfileDropdownOpen(false);
    }
  }, [props.isExpanded]);

  const isAdmin = props.loginUser && props.loginUser.isAdmin;
  const isSubscribed =
    props.UserBalanceData &&
    (!props.subscriptionDetails ||
      (props.subscriptionDetails &&
        props.subscriptionDetails.subscription &&
        props.subscriptionDetails.subscription.toLowerCase() !== "trial"));
  const isTrialing = props.subscriptionDetails?.status === "trialing";

  const profileDropdownClass = classNames("profile-main-container", {
    "profile-dropdown-open": profileDropdownOpen,
    subscribed: isSubscribed,
    expanded: props.isExpanded,
    trialing: isTrialing && calendly.enabled,
  });
  return (
    <div className="header-container">
      <div className="search-icon" onClick={props.handleToggler}>
        <img
          src={settings.mainbrand}
          className="top_logo logo_pic"
          alt="homepage"
        />
      </div>
      <div className="link-container header-inner-container">
        {dashboard.enabled && (
          <NavLink to="/dashboard" activeClassName="active" className="link">
            <span className="icon-container">
              <HomeIcon
                className="link-icon"
                width={25}
                height={25}
                strokeWidth={props.isExpanded ? 1.5 : 2}
              />
            </span>

            <span className="sidebar-page">{t("dashboard.title")}</span>
          </NavLink>
        )}
        {discovery.enabled && (
          <NavLink to="/discovery" activeClassName="active" className="link">
            <span className="icon-container">
              <MagnifyingGlassIcon
                className="link-icon"
                width={25}
                height={25}
                strokeWidth={props.isExpanded ? 1.5 : 2}
              />
            </span>

            <span className="sidebar-page">{t("discovery.title")}</span>
          </NavLink>
        )}
        {reports.enabled && (
          <NavLink to="/reports" activeClassName="active" className="link">
            <span className="report-icon  icon-container">
              <ChartPieIcon
                className="link-icon"
                width={25}
                height={25}
                strokeWidth={props.isExpanded ? 1.5 : 2}
              />
            </span>
            <span className="sidebar-page">{t("reports.title")}</span>
          </NavLink>
        )}
        {creator_lists.enabled && (
          <NavLink to="/creatorLists" activeClassName="active" className="link">
            <span className="report-icon  icon-container">
              <UsersIcon
                className="link-icon"
                width={25}
                height={25}
                strokeWidth={props.isExpanded ? 1.5 : 2}
              />
            </span>
            <span className="sidebar-page">{t("creator_lists.title")}</span>
          </NavLink>
        )}
        {campaign_manager.enabled &&
          props.subscriptionDetails?.subscription === "premium" && (
            <NavLink
              to="/campaignManager"
              activeClassName="active"
              className="link"
            >
              <span className="post-icon  icon-container">
                <PhotoIcon
                  className="link-icon"
                  width={25}
                  height={25}
                  strokeWidth={props.isExpanded ? 1.5 : 2}
                />
              </span>
              <span className="sidebar-page">
                {t("campaign_manager.title")}
              </span>
            </NavLink>
          )}
        {campaign_results.enabled && (
          <NavLink to="/campaigns" activeClassName="active" className="link">
            <span className="post-icon  icon-container">
              <PhotoIcon
                className="link-icon"
                width={25}
                height={25}
                strokeWidth={props.isExpanded ? 1.5 : 2}
              />
            </span>
            <span className="sidebar-page">Campaign Results</span>
          </NavLink>
        )}
        {follower_list.enabled && (
          <NavLink to="/emailMatch" activeClassName="active" className="link">
            <span className="icon-container">
              <EnvelopeIcon
                className="link-icon"
                width={25}
                height={25}
                strokeWidth={props.isExpanded ? 1.5 : 2}
              />
            </span>

            <span className="sidebar-page">Email Match</span>
          </NavLink>
        )}
        {follower_list.enabled && (
          <NavLink to="/lists" activeClassName="active" className="link">
            <span className="icon-container">
              <ClipboardDocumentIcon
                className="link-icon"
                width={25}
                height={25}
                strokeWidth={props.isExpanded ? 1.5 : 2}
              />
            </span>
            <span className="sidebar-page">Follower Lists</span>
          </NavLink>
        )}
        <NavLink to="/activity" activeClassName="active" className="link">
          <span className="icon-container">
            <ClipboardDocumentIcon
              className="link-icon"
              width={25}
              height={25}
              strokeWidth={props.isExpanded ? 1.5 : 2}
            />
          </span>
          <span className="sidebar-page">Activity</span>
        </NavLink>
      </div>
      <div className="profile-main">
        <div className={profileDropdownClass}>
          <div className="profile-container header-inner-container">
            {isTrialing && calendly.enabled ? (
              <Row>
                <CalendlyEmbed
                  loginUser={props.loginUser}
                  prefill={{
                    email: props.loginUser?.email,
                    name: props.loginUser?.name,
                  }}
                  isOpen={calendlyOpen}
                  onModalClose={handleCalendlyPopup}
                />
                <Col className="profile-card justify-content-center mb-3">
                  {props.isExpanded ? (
                    <Button
                      onClick={() => handleCalendlyPopup(true)}
                      className="btn btn-primary subscribe"
                    >
                      Talk to a Human
                    </Button>
                  ) : (
                    <div onClick={() => handleCalendlyPopup(true)}>
                      <PhoneIcon
                        className="phone-icon"
                        width={50}
                        height={50}
                        strokeWidth={2}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col className="profile-card justify-content-center mb-3">
                <div onClick={() => toggleSettings()}>
                  <CogIcon
                    className="settings-icon"
                    width={50}
                    height={50}
                    strokeWidth={2}
                  />
                </div>
              </Col>
              <Col className="profile-card justify-content-center">
                <div
                  className="text-center"
                  onClick={() => {
                    darkMode.toggle();
                  }}
                >
                  {!darkMode.value ? (
                    <svg
                      className="settings-icon"
                      width={50}
                      height={50}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="settings-icon"
                      width={50}
                      height={50}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                      />
                    </svg>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div
            className={`profile-balance-container header-inner-container ${
              profileDropdownOpen ? "open" : ""
            }`}
          >
            {isSubscribed ? (
              <Balance userBalance={props.UserBalanceData} />
            ) : null}

            <div
              className={`action-container text-grey header-inner-container`}
            >
              <NavLink to="/settings" className="action">
                {t("auth.sidebar.manage")}
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              </NavLink>
              <NavLink
                className="action"
                to={{ pathname: process.env.REACT_APP_SUPPORT_URL }}
                target="_blank"
              >
                {t("auth.sidebar.support")}
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              </NavLink>
              <div
                className="action sign-out"
                onClick={(e) => props.signOut(null, isAdmin)}
              >
                {t("auth.sidebar.sign_out")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    UserBalanceData: state.header.UserBalanceData,
    subscriptionDetails: state.login.subscriptionDetails,
    loginUser: state.login.loginUser,
    accountOverviewDetails: state.reports.accountOverviewDetails,
    showHeader: state.paymentPortal.showHeader,
    checkoutSessionDetails: state.login.checkoutSessionDetails,
  };
};

export default connect(mapStateToProps, {
  signOut,
  generateBillingPortal,
  getUserBalanceData,
  createCheckoutSession,
})(Sidebar);
