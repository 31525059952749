import { campaignManagerActionTypes } from "../constants";

const initialState = {
  loading: false,
  campaigns: [],
  campaignsCount: null,

  newPostModalIsOpen: false,
  loadingPostPreview: false,
  postPreview: null,
  postPreviewImage: null,
  creatorPreviewImage: null,
  loadingNewPostModal: false,

  campaignModalIsOpen: false,
  refreshCampaignsOnClose: false,
  loadingActiveCampaign: false,
  activeCampaign_id: null,
  activeCampaign: null,
  campaignPosts: [],
  campaignPostsTotalCount: null,

  updateCampaignsOnClose: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case campaignManagerActionTypes.getCampaigns.REQUEST:
      return {
        ...state,
        loading: true,
        campaigns: [],
        campaignsCount: null,
      };
    case campaignManagerActionTypes.getCampaigns.SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: payload?.results,
        campaignsCount: payload?.count,
        updateCampaignsOnClose: false,
      };
    case campaignManagerActionTypes.getCampaigns.FAILURE:
      return {
        ...state,
        loading: false,
        campaigns: [],
        campaignsCount: null,
      };
    case campaignManagerActionTypes.getCampaign.REQUEST:
      return {
        ...state,
        loadingActiveCampaign: true,
        activeCampaign: null,
      };
    case campaignManagerActionTypes.getCampaign.SUCCESS:
      return {
        ...state,
        // loadingActiveCampaign: false,
        activeCampaign: payload,
      };
    case campaignManagerActionTypes.getCampaign.FAILURE:
      return {
        ...state,
        loadingActiveCampaign: false,
        activeCampaign: null,
      };
    case campaignManagerActionTypes.addSharedUser.REQUEST:
      return {
        ...state,
        loading: true,
        activeCampaign_id: null,
      };
    case campaignManagerActionTypes.addSharedUser.SUCCESS:
      return {
        ...state,
        activeCampaign_id: payload?.result?.campaign_id,
        updateCampaignsOnClose: true,
        // loading: false,
      };
    case campaignManagerActionTypes.addSharedUser.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case campaignManagerActionTypes.removeSharedUser.REQUEST:
      return {
        ...state,
        loading: true,
        activeCampaign_id: null,
      };
    case campaignManagerActionTypes.removeSharedUser.SUCCESS:
      return {
        ...state,
        activeCampaign_id: payload?.result?.campaign_id,
        updateCampaignsOnClose: true,
        // loading: false,
      };
    case campaignManagerActionTypes.removeSharedUser.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case campaignManagerActionTypes.getCampaignPosts.REQUEST:
      return {
        ...state,
        loadingActiveCampaign: true,
        campaignPosts: [],
        campaignPostsTotalCount: null,
      };
    case campaignManagerActionTypes.getCampaignPosts.SUCCESS:
      return {
        ...state,
        loadingActiveCampaign: false,
        campaignPosts: payload?.results,
        campaignPostsTotalCount: payload?.count,
      };
    case campaignManagerActionTypes.getCampaignPosts.FAILURE:
      return {
        ...state,
        loadingActiveCampaign: false,
      };
    case campaignManagerActionTypes.deleteCampaign.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignManagerActionTypes.deleteCampaign.SUCCESS:
      return {
        ...state,
        activeCampaign: null,
        activeCampaign_id: null,
        campaignModalIsOpen: false,
      };
    case campaignManagerActionTypes.deleteCampaign.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case campaignManagerActionTypes.getPostPreview.REQUEST:
      return {
        ...state,
        loadingPostPreview: true,
        postPreview: null,
      };
    case campaignManagerActionTypes.getPostPreview.SUCCESS:
      return {
        ...state,
        loadingPostPreview: false,
        postPreview: payload,
      };
    case campaignManagerActionTypes.getPostPreview.FAILURE:
      return {
        ...state,
        loadingPostPreview: false,
        postPreview: null,
      };

    case campaignManagerActionTypes.getPostPreviewImage.REQUEST:
      return {
        ...state,
        postPreviewImage: null,
      };
    case campaignManagerActionTypes.getPostPreviewImage.SUCCESS:
      return {
        ...state,
        postPreviewImage: payload,
      };
    case campaignManagerActionTypes.getPostPreviewImage.FAILURE:
      return {
        ...state,
        postPreviewImage: null,
      };
    case campaignManagerActionTypes.getCreatorPreviewImage.REQUEST:
      return {
        ...state,
        creatorPreviewImage: null,
      };
    case campaignManagerActionTypes.getCreatorPreviewImage.SUCCESS:
      return {
        ...state,
        creatorPreviewImage: payload,
      };
    case campaignManagerActionTypes.getCreatorPreviewImage.FAILURE:
      return {
        ...state,
        creatorPreviewImage: null,
      };
    case campaignManagerActionTypes.createCampaign.REQUEST:
      return {
        ...state,
        loadingNewPostModal: true,
      };
    case campaignManagerActionTypes.createCampaign.SUCCESS:
      return {
        ...state,
        loadingNewPostModal: !payload?.resolveLoadingState,
      };
    case campaignManagerActionTypes.createCampaign.FAILURE:
      return {
        ...state,
        loadingNewPostModal: false,
      };
    case campaignManagerActionTypes.createCampaignPost.REQUEST:
      return {
        ...state,
        loadingNewPostModal: true,
        activeCampaign_id: null,
      };
    case campaignManagerActionTypes.createCampaignPost.SUCCESS:
      return {
        ...state,
        newPostModalIsOpen: false,
        activeCampaign_id: payload?.result?.campaign_id,
        campaignModalIsOpen: true,
        updateCampaignsOnClose: true,
        loadingNewPostModal: false,
      };
    case campaignManagerActionTypes.createCampaignPost.FAILURE:
      return {
        ...state,
        loadingNewPostModal: false,
      };

    case campaignManagerActionTypes.deleteCampaignPost.REQUEST:
      return {
        ...state,
        loadingActiveCampaign: true,
      };
    case campaignManagerActionTypes.deleteCampaignPost.SUCCESS:
      return {
        ...state,
        updateCampaignsOnClose: true,
      };
    case campaignManagerActionTypes.deleteCampaignPost.FAILURE:
      return {
        ...state,
        loadingActiveCampaign: false,
      };

    case campaignManagerActionTypes.setNewPostModalIsOpen.SUCCESS:
      let newStatePostModal = {
        ...state,
        newPostModalIsOpen: !!payload?.modalIsOpen,
      };
      if (!payload.modalIsOpen) {
        newStatePostModal.postPreview = null;
        newStatePostModal.postPreviewImage = null;
        newStatePostModal.creatorPreviewImage = null;
      }
      return newStatePostModal;
    case campaignManagerActionTypes.setCampaignModalIsOpen.SUCCESS:
      let newStateCampaignModal = {
        ...state,
        campaignModalIsOpen: !!payload?.modalIsOpen,
      };
      if (payload?.campaignIdToOpen) {
        newStateCampaignModal.activeCampaign_id = payload?.campaignIdToOpen;
      }
      return newStateCampaignModal;

    // case campaignManagerActionTypes.getCampaignCreators.REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case campaignManagerActionTypes.getCampaignCreators.SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     campaignCreators: payload?.results,
    //     campaignCreatorsCount: payload?.count,
    //   };
    // case campaignManagerActionTypes.getCampaignCreators.FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     campaignCreators: [],
    //     campaignCreatorsCount: null,
    //   };
    // case campaignManagerActionTypes.updateCampaign.REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case campaignManagerActionTypes.updateCampaign.SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //   };
    // case campaignManagerActionTypes.updateCampaign.FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //   };

    default: {
      return state;
    }
  }
};
