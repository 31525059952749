import React, { useState, useEffect } from "react";
import TagManager from "react-gtm-module";
import settings from "settings";
import Header from "../modules/header/index";
import IdentificationComponent from "../modules/identification/index.js";
import DashboardComponent from "../modules/dashboard";
import EmailMatchComponent from "../modules/email_match/index.js";
import LoginComponent from "../modules/login/index";
import SignupComponent from "../modules/signup/index";
import SuccessComponent from "../modules/signup/success";
import ForgotPasswordComponent from "../modules/forgotPassword/index";
import ReportsComponent from "../modules/reports/index";
import CampaignManager from "modules/campaignManager/index";
import PDFReportsComponent from "../modules/postAnalysis/index";
import TensorList from "../modules/tensorList";
import CreatorList from "../modules/creatorList";
import ErrorComponent from "../modules/error/index";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PublicRoute from "./publicRoute";
import PrivateRoute from "./privateRoute";
import history from "../history";
import Loader from "../loader";
// import UserProfile from "../modules/userProfile/index";
import Settings from "../modules/settings/index";
import PastExports from "../modules/userProfile/pastExports";
// import Alert from "react-s-alert";
import { ToastContainer } from "react-toastify";
import useDarkMode from "use-dark-mode";

// import SubscriptionFailedComponent from '../modules/paymentPortal/subscriptionFailed';
// import SubscriptionSuccessComponent from '../modules/paymentPortal/subscriptionSuccess';
// import PaymentPortal from '../modules/paymentPortal/paymentPortal';
// import PaymentConfirmationComponent from '../modules/paymentPortal/paymentConfirmation';
import ResetPassword from "../modules/forgotPassword/resetPassword";
import VerifyEmailConfirmation from "../modules/signup/verifyEmailConfirmation";
//import EmailReport from '../modules/signup/emailReport';
import AdminPanel from "../modules/adminPannel";
import AdminLogin from "../modules/login/adminLogin";
import MessengerCustomerChat from "react-messenger-customer-chat";
import GlobalLoadScreen from "modules/globalLoadScreen/GlobalLoadScreen";
const AppRoutes = (props) => {
  const {
    facebook,
    pages: {
      dashboard,
      discovery,
      reports,
      campaign_results,
      creator_lists,
      follower_list,
      campaign_manager,
      signup,
    },
  } = settings;

  useEffect(() => {
    if (!props.loginUser) return;

    let whitelistedUser = props.loginUser;
    let whitelistedSubscription = JSON.parse(
      JSON.stringify(props.subscriptionDetails)
    );

    delete whitelistedUser.token;
    delete whitelistedSubscription?.api?.api_token;

    const tagManagerArgs = {
      gtmId: "GTM-WMQ2GMZ",
      dataLayer: {
        loginUser: whitelistedUser,
        subscriptionDetails: whitelistedSubscription,
      },
    };

    if (process.env.REACT_APP_BRAND === "tensorsocial") {
      TagManager.initialize(tagManagerArgs);
    }

    if (!process.env.REACT_APP_INTERCOM_APP_ID) return;
    let bootOptions = {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    };
    if (!props.loginUser.isAdmin) {
      const {
        email,
        name,
        id: user_id,
        intercomHash: user_hash,
      } = props.loginUser;
      bootOptions = { ...bootOptions, email, name, user_id, user_hash };
    }
    if (!window.Intercom) return;
    window.Intercom("boot", bootOptions);
    history.listen((location) => {
      // Ping intercom on every page change
      window.Intercom("update");
    });
  });

  const darkMode = useDarkMode(false);
  const sidebarCollapsed = localStorage.getItem("sidebar");
  const [isExpanded, setIsExpanded] = useState(sidebarCollapsed ? false : true);

  const handleToggler = (toggle = false) => {
    if (isExpanded) {
      setIsExpanded(false);
      localStorage.setItem("sidebar", true);
      return;
    }

    setIsExpanded(!isExpanded && toggle);
    localStorage.removeItem("sidebar");
  };

  return (
    <Router history={history}>
      {(props.isIdentificationReducerLoading ||
        props.isIdentificationReducerSearchingInfluencers ||
        props.isReportsReducerLoading ||
        props.isPdfReportsReducerLoading ||
        props.loginReducerLoading ||
        props.isSignupReducerLoading ||
        props.isUserProfileReducerLoading ||
        props.isForgotPasswordLoading ||
        props.isPaymentPortalReducerLoading ||
        props.isAdminPanelLoading ||
        props.tensorListsLoading ||
        props.emailMatchLoading ||
        props.creatorTrackingLoading ||
        props.campaignManagerLoading ||
        props.creatorListsLoading) &&
      props.refreshLoading ? (
        <Loader
          searchingInfluencers={
            props.isIdentificationReducerSearchingInfluencers
          }
        />
      ) : null}

      <div
        className={
          props.loginUser
            ? !isExpanded
              ? "outer-header collapsed"
              : "outer-header show"
            : "outer-header-hidden"
        }
      >
        <Header
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          handleToggler={handleToggler}
        />
      </div>
      <div
        id="app"
        style={{
          transition: "all 1s cubic-bezier(0, 1, 0.5, 1)",
          paddingLeft: props.loginUser
            ? isExpanded
              ? 300
              : window.innerWidth * 0.05
            : 0,
        }}
        className="content"
      >
        <Switch>
          <Redirect from="/userProfile" to="/dashboard" />
          <Redirect from="/influencerDiscovery" to="/discovery" />
          <Redirect from="/postAnalysis/:id?" to="/dashboard" />
          <Redirect from="/campaigns/:id?" to="/dashboard" />
          <Redirect from="/integrations" to="/settings" />
          <PrivateRoute
            exact
            path="/settings"
            component={Settings}
            loginUser={props.loginUser}
          />
          <PrivateRoute
            exact
            path="/activity"
            component={PastExports}
            loginUser={props.loginUser}
          />
          {discovery.enabled && (
            <PrivateRoute
              exact
              path="/discovery"
              component={IdentificationComponent}
              loginUser={props.loginUser}
            />
          )}

          {dashboard.enabled && (
            <PrivateRoute
              exact
              path="/dashboard"
              component={DashboardComponent}
              loginUser={props.loginUser}
            />
          )}
          <PublicRoute
            exact
            path="/"
            component={LoginComponent}
            loginUser={props.loginUser}
          />
          <PublicRoute
            exact
            path="/admin"
            component={AdminLogin}
            loginUser={props.loginUser}
          />
          {signup.enabled && (
            <PublicRoute
              exact
              path="/signup"
              component={SignupComponent}
              loginUser={props.loginUser}
            />
          )}
          <PublicRoute
            exact
            path="/forgotPassword"
            component={ForgotPasswordComponent}
            loginUser={props.loginUser}
          />
          {reports.enabled && (
            <Route
              path="/reports"
              render={({ match: { url } }) => (
                <>
                  <PrivateRoute
                    exact
                    path={`${url}/`}
                    component={ReportsComponent}
                    loginUser={props.loginUser}
                  />
                  <PublicRoute
                    path={`${url}/:id`}
                    component={ReportsComponent}
                    loginUser={props.loginUser}
                  />
                </>
              )}
            />
          )}
          {follower_list.enabled && (
            <PrivateRoute
              exact
              path="/emailMatch"
              component={EmailMatchComponent}
              loginUser={props.loginUser}
            />
          )}
          {campaign_manager.enabled &&
            props.subscriptionDetails?.subscription === "premium" && (
              <PrivateRoute
                exact
                path="/campaignManager/:id?"
                component={CampaignManager}
                loginUser={props.loginUser}
              />
            )}
          {campaign_results.enabled && (
            <PrivateRoute
              exact
              path="/campaigns/:id?"
              component={PDFReportsComponent}
              loginUser={props.loginUser}
            />
          )}
          <PublicRoute exact path="/resetPassword" component={ResetPassword} />
          <PublicRoute
            exact
            path="/verify"
            component={VerifyEmailConfirmation}
          />
          <PublicRoute exact path="/success" component={SuccessComponent} />
          {follower_list.enabled && (
            <PrivateRoute
              exact
              path="/lists"
              component={TensorList}
              loginUser={props.loginUser}
            />
          )}
          {creator_lists.enabled && (
            <PrivateRoute
              exact
              path="/creatorLists"
              component={CreatorList}
              loginUser={props.loginUser}
            />
          )}
          <PrivateRoute
            exact
            path="/admin/dashboard"
            component={AdminPanel}
            loginUser={props.loginUser}
          />
          <PublicRoute exact path="/loading" component={GlobalLoadScreen} />
          <Route exact path="*" component={ErrorComponent} />
        </Switch>
      </div>
      {facebook.enabled && (
        <div>
          <MessengerCustomerChat
            pageId="350725998968349"
            appId="936144840094609"
            htmlRef="https://app.tensorsocial.com"
          />
        </div>
      )}
      <ToastContainer limit={3} theme={darkMode.value ? "dark" : "colored"} />
    </Router>
  );
};
const mapStateToProps = (state) => ({
  isIdentificationReducerLoading: state.identification.loading,
  isIdentificationReducerSearchingInfluencers:
    state.identification.searchingInfluencers,
  isReportsReducerLoading: state.reports.loading,
  isPdfReportsReducerLoading: state.postAnalysis.loading,
  loginReducerLoading: state.login.loading,
  loginUser: state.login.loginUser,
  subscriptionDetails: state.login.subscriptionDetails,
  isSignupReducerLoading: state.signup.loading,
  isUserProfileReducerLoading: state.userProfile.loading,
  isPaymentPortalReducerLoading: state.paymentPortal.loading,
  isSubscriptionChoose: state.paymentPortal.choosePlanDetails,
  isForgotPasswordLoading: state.forgotPassword.loading,
  isAdminPanelLoading: state.adminPanel.loading,
  tensorListsLoading: state.tensorLists.loading,
  emailMatchLoading: state.emailMatch.loading,
  creatorListsLoading: state.creatorLists.loading,
  campaignManagerLoading: state.campaignManager.loading,
  refreshLoading: state.login.refreshLoading,
});
export default connect(mapStateToProps, null)(AppRoutes);
